/*
 * infieldLabel styles
 */

.js .infield-label {
  display: inline-block;
  position: relative;

  // label as placeholder
  label {
    color: #777;
    cursor: text;
    display: block;
    font-size: 14px;
    left: 0;
    line-height: 40px;
    margin: 0;
    padding: 0 10px;
    position: absolute;
    top: 0;
    white-space: nowrap;
    width: 100%;
  }

  // focused style
  &.placeholder-focus {
    label {
      background-color: rgba(#000, 0.85);
      box-shadow: 0 0 8px rgba(#000, 0.3);
      color: #fff;
      line-height: 32px;
      top: -40px;
      width: auto;

      &::after {
        border-top: 5px solid rgba(#000, 0.8);
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        bottom: -5px;
        content: '';
        display: block;
        height: 0;
        left: 10px;
        position: absolute;
      }
    }
  }

  // visually hidden label
  &.placeholder-hide {
    label {
      left: -9999px;
      right: auto;
      top: -9999px;
    }
  }
}
